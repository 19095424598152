import Vue from 'vue';
import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import '../admin/public/bootstrap';
import LoadScript from '@common/components/LoadScript.vue';
import HasState from '../javascripts/has-state';
import PlausibleEvent from '../javascripts/plausible-event';
import { iframeResize } from 'iframe-resizer';

require('../styles/application.scss');

// Add all images from the "images" folder to the pipeline
require.context('../images', true);

Rails.start();
Turbolinks.start();

Vue.component('load-script', LoadScript);
Vue.component('has-state', HasState);
Vue.component('plausible-event', PlausibleEvent);

let vueApp;

// Bootstrap the Vue root instance
document.addEventListener('turbolinks:load', () => {
  vueApp = new Vue({ el: '#js-wrapper' });

  if (document.querySelector('#tweets') && typeof twttr !== 'undefined') {
    twttr.widgets.createTimeline(
      { sourceType: 'profile', screenName: 'FO_Coalition' },
      document.querySelector('#tweets'),
      { height: 720 }
    );
  }

  iframeResize({}, '#livecasts-widget');
});

document.addEventListener('turbolinks:visit', () => {
  if (vueApp) vueApp.$destroy();
});
